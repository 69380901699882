import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "circle dashboard-icon circle-active-donor" }
const _hoisted_2 = { class: "legend-title" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "form-group col-md-8 col-lg-8 col-xl-8" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_widget_links = _resolveComponent("widget-links")!
  const _component_widget_options = _resolveComponent("widget-options")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_proto_table_list = _resolveComponent("proto-table-list")!
  const _component_proto_text_area_input = _resolveComponent("proto-text-area-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_widget_section = _resolveComponent("widget-section")!
  const _component_base_widget = _resolveComponent("base-widget")!

  return (_openBlock(), _createBlock(_component_base_widget, {
    title: "Reminders",
    widget: _ctx.uiWidget
  }, {
    icon: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'user'] })
      ])
    ]),
    linkID: _withCtx(() => [
      _createVNode(_component_widget_links, {
        "options-id": "idBookmarks",
        "section-id": "bodyBookmarks"
      })
    ]),
    options: _withCtx(() => [
      _createVNode(_component_widget_options, {
        id: "idBookmarks",
        widget: _ctx.uiWidget
      }, null, 8, ["widget"])
    ]),
    "widget-contents": _withCtx(() => [
      _createVNode(_component_widget_section, { id: "bodyBookmarks" }, {
        body: _withCtx(() => [
          _createVNode(_component_table_toolbar, {
            createButton: _ctx.tableConfig.createButton,
            createText: _ctx.tableConfig.createText,
            onTableCreateRow: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearForm()))
          }, null, 8, ["createButton", "createText"]),
          _createVNode(_component_proto_table_list, {
            "table-id": "reminders-table",
            ref: "remindersWidgetTable",
            tabbableColumn: "createdAtDisplay",
            "table-config": _ctx.tableConfig,
            onTableRowClick: _ctx.editSelected,
            highlightSelection: true
          }, null, 8, ["table-config", "onTableRowClick"]),
          _createVNode(_component_form_layout, { "form-id": "protowidgetreminders-form" }, {
            title: _withCtx(() => [
              _createElementVNode("legend", null, [
                _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.isNew ? 'NEW REMINDER' : 'SELECTED REMINDER'), 1)
              ])
            ]),
            contents: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_proto_text_area_input, {
                    inputId: "reminders-description",
                    name: "Description",
                    maxLength: 500,
                    showGuidingText: true,
                    guidingText: "Include patient name and MRN where applicable.",
                    modelValue: _ctx.editState.description,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.description) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_date_input, {
                    inputId: "reminders-due-date",
                    name: "Due Date",
                    modelValue: _ctx.editState.due_date,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.due_date) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_text_input, {
                    inputId: "bookmark-link",
                    name: _ctx.$t('link'),
                    modelValue: _ctx.editState.link,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editState.link) = $event))
                  }, null, 8, ["name", "modelValue"])
                ])
              ])
            ]),
            save: _withCtx(() => [
              _createVNode(_component_save_toolbar, {
                show: true,
                ref: "saveReminders",
                onSave: _ctx.performSave,
                label: "Save Reminder",
                cancelButton: true,
                onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearForm()))
              }, null, 8, ["onSave"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["widget"]))
}