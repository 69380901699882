<template>
  <!-- Form layout -->
  <validation-observer 
    ref="validations"
    v-if="checklistTask.showOutcomeForm">
    <form-layout
      :disabled="!enableForm"
      form-id="checklist-outcome-form"
    >
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="selection.isNew" class="legend-title">
            {{$t('new_outcome')}}
          </h5>
          <h5 v-else class="legend-title has-guiding-text">
            {{$t('selected_outcome')}}
          </h5>
        </legend>
      </template>
      <template v-slot:contents>
        <!-- Action Toolbar -->
        <action-toolbar
          :permittedActions="editState.permitted_actions"
          :destroyTitleText="$t('delete_outcome')"
          :destroyGuidingText="$t('delete_outcome_question')"
          @destroy="onDestroy"
        />

        <div class="row">
          <div class="standard-form-group">
            <date-input
              :ruleKey="`checklist_outcomes_${checklistTask.id}.service_date`"
              :name="$t('service_date')"
              :input-id="`checklist_outcomes_${checklistTask.id}_service_date`"
              v-model="editState.service_date"
            />
          </div>
          <div class="standard-form-group">
            <date-input
              :ruleKey="`checklist_outcomes_${checklistTask.id}.expiration_date`"
              :name="$t('expiration_date')"
              :input-id="`checklist_outcomes_${checklistTask.id}_expiration_date`"
              v-model="editState.expiration_date"
            />
          </div>
          <div class="standard-form-group">
            <select-input
              :ruleKey="`checklist_outcomes_${checklistTask.id}.imaging_received`"
              :select-id="`checklist_outcomes_${checklistTask.id}_imaging_received`"
              :name="$t('imaging_received')"
              :options="checklistTakeOutcomeImagingReceivedOptions"
              v-model="editState.imaging_received"
            />
          </div>
        </div>
        <div class="row">
          <div class="standard-full-width-group">
            <text-area-input
              :ruleKey="`checklist_outcomes_${checklistTask.id}.outcome`"
              :input-id="`checklist_outcomes_${checklistTask.id}_outcome`"
              :name='$t("outcome")'
              v-model="editState.outcome"
              rows="4"
            />
          </div>
        </div>
      </template>
    </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { State, Getter } from 'vuex-facing-decorator';
import { Component, Prop, Watch, mixins } from "vue-facing-decorator";
import FormLayout from '@/components/shared/FormLayout.vue';
import { i18nMessages } from "@/i18n";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { UIRecipient } from '@/UIModels/recipient';
import { UIJourney } from '@/UIModels/journey';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { SaveResult, APIPermittedActions } from "@/types";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { parseFormErrors } from '@/utils';
import { IdLookup } from '@/store/validations/types';
import DateInput from '@/components/shared/DateInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import { GenericCodeValue } from '@/store/types';
import { RulesQuery } from '@/types';
import { Rules } from '@/store/validations/types';
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIChecklistTask, UIChecklistOutcome } from "@/UIModels/journey/checklist";
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import ActionToolbar from '@/components/shared/ActionToolbar.vue';

@Component({
  components: {
    FormLayout,
    SaveToolbar,
    DateInput,
    TextInput,
    SelectInput,
    TextAreaInput,
    ActionToolbar
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/ChecklistSection.json'),
    require('@/components/organs/shared/_locales/common.json'),
  ]),
  emits: [
    'destroy'
  ],
})

export default class ChecklistOutcomeForm extends mixins(DateUtilsMixin) {
  @State(state => state.lookups.checklist_task_outcome_imaging_received) checklistTakeOutcomeImagingReceivedOptions!: GenericCodeValue[];

  // Selection instance
  @Prop({ required: true }) selection!: UIListFormSelection;
  @Prop({ required: true }) checklistTask!: UIChecklistTask;
  @Prop({ default: false }) canSave!: boolean;

  // Editable view model for the form
  private editState = new UIChecklistOutcome();
  private permittedActions: string[] = [];

  private isLoadingForm = false;

  public getOutcomeEditState(): UIChecklistOutcome {
    return this.editState;
  }

  // Handle deleting an outcome. Called after user accepts delete warning modal dialog popup
  private async onDestroy(): Promise<void> {
    try {
      const payload: any = {
        selected: this.editState,
        recipient: this.currentRecipient,
        journey: this.currentJourney,
        task: this.checklistTask
      };
      await this.editState.delete(payload);
      this.$emit('destroy');

      // this.prepareNewEntry();
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Can we enable the form?
  get enableForm(): boolean {
    if (this.currentJourney.isNew) return false;
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    if (this.currentJourney.isNew) return false;
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    if (this.currentJourney.isNew) return false;
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    if (!this.editState) return false;
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Which Journey view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentJourney(): UIJourney {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentJourney as UIJourney;
  }

  // Process change to "selection" prop
  // NOTE: this will initialize Follow Up Form
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(): void {
    this.resetEditState();
    this.resetFormErrors();
  }

  // Initialize form edit state by requesting a copy of the "selection" view model
  private async resetEditState(): Promise<void> {
    this.isLoadingForm = true;

    const newEditState = new UIChecklistOutcome();
    const query = this.selection.isNew ? this.ruleQueryDefaults : this.ruleQueryEditState;

    if (this.checklistTask.id && !this.checklistTask.isNew) {
      await newEditState.load({ uiJourney: this.currentJourney, task: this.checklistTask, id: this.selection.id as string });
    } else {
      await newEditState.load({ uiJourney: this.currentJourney, task: this.checklistTask, id: '' });
    }

    this.editState = newEditState;
    this.isLoadingForm = false;
  }

  // What validation rule query parameters are needed based on lookup defaults?
  get ruleQueryDefaults(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // Resets Form Errors
  public resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process error save result
  public handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    this.resetEditState();
    this.resetFormErrors();
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    this.resetEditState();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const results: { [key: string]: string } = {};

    // outcomes
    results[`checklist_${this.checklistTask.id}.service_date`]       = `checklist_outcomes_${this.checklistTask.id}_service_date`;
    results[`checklist_${this.checklistTask.id}.expiration_date`]    = `checklist_outcomes_${this.checklistTask.id}_expiration_date`;
    results[`checklist_${this.checklistTask.id}.imaging_received`]   = `checklist_outcomes_${this.checklistTask.id}_imaging_received`;
    results[`checklist_${this.checklistTask.id}.outcome`]            = `checklist_outcomes_${this.checklistTask.id}_outcome`;

    // tasks
    results[`checklist_${this.checklistTask.id}.checklist_task.name`]     = `checklist_${this.checklistTask.id}.name`;
    results[`checklist_${this.checklistTask.id}.checklist_task.external`] = `checklist_${this.checklistTask.id}.external`;
    results[`checklist_${this.checklistTask.id}.checklist_task.status`]   = `checklist_${this.checklistTask.id}.status`;

    return results;
  }
}
</script>
