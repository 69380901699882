// Master list of prototypes
export enum PROTOTYPES {
  ChecklistReorder = 'proto_checklists_reorder',
  CommunicationInformation = 'proto_communication_information',
  TransportationAndMobility = 'proto_transportation_and_mobility',
  PaceAndEngagement = 'proto_pace_and_engagement',
  PrioritizationReport = 'proto_prioritization_report',
  SchedulingPreferences = 'proto_scheduling_preferences',
  ProtoRecipientsListings = 'proto_recipient_listings',
  CareGivers = 'proto_care_givers',
  WidgetReminders = 'proto_widget_reminders',
  LiverSpecificDetails = 'proto_liver_specific_details',
  LiverSodiumMeld = 'proto_liver_sodium_meld',
  EgfrAndDiaylsis = 'proto_egfr_and_dialysis',
  EgfrSection = 'proto_egfr_section',
  DiaylsisEvents = 'proto_dialysis_events',
  ShowBloodType = 'proto_blood_type',
  KidneySpecificDetails = 'proto_kidney_specific_details',
  NotesMultipleAutocomplete = "proto_notes_multiple_autocomplete",
  HeartSpecificDetails = 'proto_heart_specific_details',
  LiverInformation = 'proto_liver_information',
  KidneyInformation = 'proto_kidney_information',
  KidneyLivingDonation = 'proto_kidney_living_donation',
  HeartHemodynamicInformation = 'proto_heart_hemodynamic_information',
  HeartEchoResult = 'proto_heart_echo_results',
  PatientHistory = 'proto_patient_history',
  MedicalHistory = 'proto_medical_history',
  SurgicalHistory = 'proto_surgical_history',
  SocialHistory = 'proto_social_history'
}
