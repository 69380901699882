<template>
    <sub-section v-if="isProtoMedicalHistoryEnabled"
      sub-section-id="proto-medical-history-section"
      :title="title"
    >
    <template v-slot:contents>
      
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="true"
        createText="Add Condition"
        @table-create-row="clearForm"
      >
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="conditionsTable"
        tabbableColumn="calculated_date"
        table-id="condition-table"
        :table-config="conditionTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
     
        <form-layout
        form-id="condition-form">
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 v-if="isNew" class="legend-title">
                NEW CONDITION
              </h5>
              <h5 v-else class="legend-title has-guiding-text">
                SELECTED CONDITION
              </h5>
            </legend>
          </template>
       

        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <text-input
              input-id="condition"
                name="Condition"
                rules="required" 
                validation-id="condition"
                v-model="editState.condition"
              />
            </div>

            <div class="standard-form-group-large">
              <text-area-input input-id="condition-comment" name="Comment" rows="4" v-model="editState.comment"  />
            </div>
          </div>

        </template>
        <template v-slot:save>
          <save-toolbar
            :show="true"     
            ref="saveCondition"
            label="Save Condition"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>


  
        </form-layout>    
    </template>
  </sub-section>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface MedicalHistoryForm {
  id: string,
  condition_date: Date|string,
  condition: string
  comment: string
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextAreaInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    NumberInput,
    TextInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoMedicalHistorySection extends mixins(DateUtilsMixin) {

  private title: any = "MEDICAL HISTORY";
  private isNew: any = true;

  private editState: MedicalHistoryForm = {
    id: '',
    condition_date: "",
    condition: "",
    comment: ""
  };

  get isProtoMedicalHistoryEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.MedicalHistory);
  }

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private conditions: any = [];

  public perPage = 10;
  public currentPageTable = 1;

  get conditionTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Date Added', field: 'conditionDateDisplayValue',  width: '125px'},
      { label: 'Condition', field: 'condition'},
      { label: 'Comment', field: 'comment'},
    ],
    empty: 'Use the form below to add a condition.',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'condition_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

private async handleArchive(): Promise<void> {
  console.log('archive');
}

private async handleRestore(): Promise<void> {
  console.log('restore');
}

      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveCondition as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.MedicalHistory, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: MedicalHistoryForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.conditions = response;
}

get tableRows() {
  const conditions = this.conditions || [];

  if (conditions.length > 0) {

  return conditions.map((condition: any) => {
    return {
      id: condition.id,
      condition_date: condition.condition_date,
      condition: condition.condition,
      conditionDateDisplayValue: this.parseDisplayDateUi(condition.condition_date) || '-',
      comment: condition.comment || '-'
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let conditions = this.conditions || [];

  const payload: MedicalHistoryForm = {
    id: Math.random().toString(16).slice(2),
    condition_date: new Date().toLocaleDateString('en-CA'),
    condition: this.editState.condition,
    comment: this.editState.comment
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = conditions.findIndex((meld: any) => meld.id == this.editState.id);
    
    if (foundIndex != -1) {
      conditions[foundIndex] = payload;
    }
  } else {  
    conditions.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, conditions);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
    if (row) {  
      this.editState.id = row.row.id;
      this.editState.condition_date = row.row.condition_date;
      this.editState.condition = row.row.condition;
      this.editState.comment = row.row.comment;
      this.isNew = false;
    }
  }

  public clearForm(): any {
    this.isNew = true;
    this.editState.condition_date = '';
    this.editState.condition = "";
    this.editState.comment = "";
  }

  rowStyleClass(row: any) {
  // if row editable, change color
  return row.editable ? 'tr-highlight-green' : null;
}

  // Reference to table
  get conditionsTable(): any {
    return this.$refs.conditionsTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.conditionsTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.conditionsTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveCondition as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}



</script>

<style>
</style>
