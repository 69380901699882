<template>
    <sub-section v-if="isProtoSurgicalHistoryEnabled"
      sub-section-id="proto-surgical-history-section"
      :title="title"
    >
    <template v-slot:contents>
      
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="true"
        createText="Add Previous Surgery"
        @table-create-row="clearForm"
      >
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="surgeryTable"
        tabbableColumn="calculated_date"
        table-id="surgery-table"
        :table-config="surgeryTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
     
        <form-layout
        form-id="surgery-form">
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 v-if="isNew" class="legend-title">
                ADD PREVIOUS SURGERY
              </h5>
              <h5 v-else class="legend-title has-guiding-text">
                SELECTED PREVIOUS SURGERY
              </h5>
            </legend>
          </template>
       

        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <text-input
              input-id="surgery"
                name="Previous Surgery"
                rules="required" 
                validation-id="surgery"
                v-model="editState.surgery"
              />
            </div>

            <div class="standard-form-group-large">
              <text-area-input input-id="surgery-comment" name="Comment" rows="4" v-model="editState.comment"  />
            </div>
          </div>

        </template>
        <template v-slot:save>
          <save-toolbar
            :show="true"     
            ref="saveSurgery"
            label="Save Previous Surgery"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>
      </form-layout>    
    </template>
  </sub-section>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import TextInput from "@/components/shared/TextInput.vue";
import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface SurgicalHistoryForm {
  id: string,
  surgery_date: Date|string,
  surgery: string
  comment: string
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextAreaInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    NumberInput,
    TextInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoSurgicalHistorySection extends mixins(DateUtilsMixin) {

  private title: any = "SURGICAL HISTORY";
  private isNew: any = true;

  private editState: SurgicalHistoryForm = {
    id: '',
    surgery_date: "",
    surgery: "",
    comment: ""
  };

  get isProtoSurgicalHistoryEnabled(): boolean {
    return useCurrentPageStore().prototypes.isEnabled(PROTOTYPES.SurgicalHistory);
  }

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private surgerys: any = [];

  public perPage = 10;
  public currentPageTable = 1;

  get surgeryTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'Date Added', field: 'surgeryDateDisplayValue',  width: '125px'},
      { label: 'Previous Surgery', field: 'surgery'},
      { label: 'Comment', field: 'comment'},
    ],
    empty: 'Use the form below to add a previous surgery.',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'surgery_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

// Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveSurgery as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.SurgicalHistory, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: SurgicalHistoryForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.surgerys = response;
}

get tableRows() {
  const surgerys = this.surgerys || [];

  if (surgerys.length > 0) {

  return surgerys.map((surgery: any) => {
    return {
      id: surgery.id,
      surgery_date: surgery.surgery_date,
      surgery: surgery.surgery,
      surgeryDateDisplayValue: this.parseDisplayDateUi(surgery.surgery_date) || '-',
      comment: surgery.comment || '-'
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let surgerys = this.surgerys || [];

  const payload: SurgicalHistoryForm = {
    id: Math.random().toString(16).slice(2),
    surgery_date: new Date().toLocaleDateString('en-CA'),
    surgery: this.editState.surgery,
    comment: this.editState.comment
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = surgerys.findIndex((meld: any) => meld.id == this.editState.id);
    
    if (foundIndex != -1) {
      surgerys[foundIndex] = payload;
    }
  } else {  
    surgerys.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, surgerys);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
    if (row) {  
      this.editState.id = row.row.id;
      this.editState.surgery_date = row.row.surgery_date;
      this.editState.surgery = row.row.surgery;
      this.editState.comment = row.row.comment;
      this.isNew = false;
    }
  }

  public clearForm(): any {
    this.isNew = true;
    this.editState.surgery_date = '';
    this.editState.surgery = "";
    this.editState.comment = "";
  }

  rowStyleClass(row: any) {
  // if row editable, change color
  return row.editable ? 'tr-highlight-green' : null;
}

  // Reference to table
  get surgeryTable(): any {
    return this.$refs.surgeryTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.surgeryTable.resetSelection();
  }

  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveSurgery as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}



</script>

<style>
</style>
