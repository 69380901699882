import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "filter-section-action-row" }
const _hoisted_2 = { class: "filter-section-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "legend-title"
}
const _hoisted_4 = {
  key: 1,
  class: "legend-title has-guiding-text"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "standard-form-group" }
const _hoisted_7 = { class: "standard-form-group" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "standard-form-group-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_table_toolbar = _resolveComponent("table-toolbar")!
  const _component_table_list = _resolveComponent("table-list")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_sub_section = _resolveComponent("sub-section")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_sub_section, {
      "sub-section-id": "proto-egfr-section",
      title: _ctx.title
    }, {
      contents: _withCtx(() => [
        _createVNode(_component_table_toolbar, {
          createButton: true,
          createText: "Create eGFR",
          onTableCreateRow: _ctx.clearForm
        }, {
          "button-bar": _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_filter_component, {
                  fieldID: "contactFilter",
                  showFilter: false,
                  showArchived: false
                })
              ])
            ])
          ]),
          _: 1
        }, 8, ["onTableCreateRow"]),
        _createVNode(_component_table_list, {
          ref: "egfrsTable",
          tabbableColumn: "calculated_date",
          "table-id": "egfr-table",
          "table-config": _ctx.egfrTableConfig,
          onTableRowClick: _ctx.editSelected,
          highlightSelection: true,
          rowStyleClass: _ctx.rowStyleClass
        }, null, 8, ["table-config", "onTableRowClick", "rowStyleClass"]),
        _createVNode(_component_form_layout, { "form-id": "egfrs-form" }, {
          title: _withCtx(() => [
            _createElementVNode("legend", null, [
              (_ctx.isNew)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_3, " NEW eGFR "))
                : (_openBlock(), _createElementBlock("h5", _hoisted_4, " SELECTED eGFR "))
            ])
          ]),
          contents: _withCtx(() => [
            _createVNode(_component_form_layout, { "form-id": "dialysis-score-form" }, {
              contents: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_date_input, {
                      inputId: "egfr-date",
                      rules: "required",
                      name: "eGFR Date",
                      modelValue: _ctx.editState.egfr_date,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.egfr_date) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_number_input, {
                      inputId: "egfr",
                      name: "eGFR",
                      append: true,
                      rules: "required",
                      appendText: "mL/min/1.73m²",
                      step: "0.1",
                      modelValue: _ctx.editState.egfr,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editState.egfr) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_text_area_input, {
                      "input-id": "meld-comments",
                      name: "Comments",
                      rows: "4",
                      modelValue: _ctx.editState.comments,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editState.comments) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              save: _withCtx(() => [
                _createVNode(_component_save_toolbar, {
                  show: true,
                  ref: "saveEgfr",
                  label: "Save eGFR",
                  cancelButton: true,
                  onSave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.performSave())),
                  onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearForm()))
                }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}