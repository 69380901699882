<template>
  <form>
    <sub-section
      sub-section-id="proto-egfr-section"
      :title="title"
    >
    <template v-slot:contents>
      
      <!-- Filter Bar + Create Button -->
      <table-toolbar
        :createButton="true"
        createText="Create eGFR"
        @table-create-row="clearForm"
      >
        <template v-slot:button-bar>
          <div class="filter-section-action-row">
            <div class="filter-section-wrapper">
              <filter-component
                fieldID="contactFilter"
                :showFilter="false"
                :showArchived="false"
              />
            </div>
          </div>
        </template>
      </table-toolbar>

      <!-- List of Items, or History List -->
      <table-list
        ref="egfrsTable"
        tabbableColumn="calculated_date"
        table-id="egfr-table"
        :table-config="egfrTableConfig"
        @table-row-click="editSelected"
        :highlightSelection="true"
        :rowStyleClass="rowStyleClass"
      />

      <!-- Form layout -->
     
        <form-layout
        form-id="egfrs-form">
          <template v-slot:title>
            <!-- Mode indicator / subsection form title -->
            <legend>
              <h5 v-if="isNew" class="legend-title">
                NEW eGFR
              </h5>
              <h5 v-else class="legend-title has-guiding-text">
                SELECTED eGFR
              </h5>
            </legend>
          </template>
          <template v-slot:contents>
      <!-- Form layout -->
      <form-layout
      form-id="dialysis-score-form">
        <template v-slot:contents>
          <div class="row">
            <div class="standard-form-group">
              <date-input
                inputId="egfr-date"
                rules="required" 
                name='eGFR Date'
                v-model="editState.egfr_date"   
              />
            </div>
            <div class="standard-form-group">
                <number-input
                  inputId="egfr"
                  name="eGFR"
                  :append="true"
                  rules="required"
                  appendText="mL/min/1.73m²"
                  step="0.1"
                  v-model="editState.egfr"
                />
              </div>
       
          </div>
          <div class="row">
         
         
          <div class="standard-form-group-large">
              <text-area-input input-id="meld-comments" name="Comments" rows="4" v-model="editState.comments"  />
            </div>
          </div>

        </template>
        <template v-slot:save>
          <save-toolbar
            :show="true"     
            ref="saveEgfr"
            label="Save eGFR"
            :cancelButton="true"
            @save="performSave()"
            @cancel="clearForm()"
          />
        </template>


      </form-layout>
      
    </template>
        </form-layout>    
    </template>
    </sub-section>
</form>
</template>


<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Watch,
  mixins
} from 'vue-facing-decorator';
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import TableToolbar from '@/components/shared/TableToolbar.vue';
import FilterComponent from "@/components/shared/filter/FilterComponent.vue";
import TableList from '@/components/shared/TableList.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import { useCurrentPageStore } from '@/stores/currentPage';
import { PROTOTYPES } from '@/UIModels/prototypes/list';
import { UIPrototypeBucket } from '@/UIModels/prototypes/prototypeBucket';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import FormLayout from '@/components/shared/FormLayout.vue';
import ActionToolbar from '@/components/shared/ActionToolbar.vue';
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import {
  SaveProvider,
  TableConfig,
} from '@/types';

const PAGE_SIZES = [5, 10, 25];
const EDITSTATE_KEY = 'editState';

interface EgfrForm {
  id: string,
  egfr_date: Date|null,
  egfr: number|null,
  comments: string
}

@Component({
  components: {
    CardSection,
    FilterComponent,
    SubSection,
    TableToolbar,
    TableList,
    TextAreaInput,
    SaveToolbar,
    FormLayout,
    ActionToolbar,
    DateInput,
    NumberInput
  },
  emits: [
    'cancel',
  ],
})
export default class ProtoEgfrSection extends mixins(DateUtilsMixin) {

  private title: any = "eGFR";
  private isNew: any = true;
  private isArchiving = false;
  private isRestoring = false;
  private isLoadingActions = false;

  private editState: EgfrForm = {
    id: '',
    egfr_date: null,
    egfr: null,
    comments: ""
  };

  // Create a prototype bucket to store arbitrary key/value data
  private protoBucket!: UIPrototypeBucket;

  private egfrs: any = [];

  private isTableUpdating = false;
  private taskInProgress = false;
  public perPage = 10;
  public currentPageTable = 1;
  private isLoading = true;
  private isSubSectionLoading = true;
  private selectedItem = '';
  private emptyContactMethodForm = '';
  private displayFilter = true;
  private setFilters: any = {};
  private modalMode = ""
  private rows = [];

  get egfrTableConfig(): TableConfig {
  return {
    data: this.tableRows || [],
    columns: [
      { label: 'eGFR Date', field: 'egfrDateDisplayValue',  width: '125px'},
      { label: 'eGFR', field: 'egfr',  width: '125px' },
      { label: 'Comments', field: 'comments'},
    ],
    empty: 'Use the form below to add a new eGFR measurement.',
    sortOptions: {
      enabled: false,
      initialSortBy: [{ field: 'egfr_date', type: 'desc' }]
    },
    pagination: true,
    paginationOptions: {
      enabled: true,
      perPageDropdown: PAGE_SIZES,
      dropdownAllowAll: false,
      position: 'bottom'
    }
  };
}

private async handleArchive(): Promise<void> {
  console.log('archive');
}

private async handleRestore(): Promise<void> {
  console.log('restore');
}

      // Each save toolbar will need a unique ref
get saveToolbar(): SaveToolbar {
  return this.$refs.saveEgfr as SaveToolbar;
}

// NOTE: add 'async' to functions that need to get key/value items from prototype bucket storage
async mounted() {
  // No-op if prototypes not enabled
  if (!useCurrentPageStore().configuration.prototypes) return;

  // Make a per-recipient prototype bucket
  this.protoBucket = new UIPrototypeBucket(PROTOTYPES.EgfrSection, { recipientId: this.$route.params.id });

  // NOTE: use 'await' when calling 'getItem' to load from prototype bucket storage
  const response: EgfrForm = await this.protoBucket.getItem(EDITSTATE_KEY);
  if (!response) return;

  this.egfrs = response;
}

get tableRows() {
  const egfrs = this.egfrs || [];

  if (egfrs.length > 0) {

  return egfrs.map((egfr: any) => {
    return {
      id: egfr.id,
      egfr_date: egfr.egfr_date,
      egfrDateDisplayValue: this.parseDisplayDateUi(egfr.egfr_date) || '-',
      egfr: egfr.egfr,
      comments: egfr.comments
    };
  });
}
}

public performSave(): any {
  // Call the 'startSaving' method to begin save animation
  if (this.saveToolbar) this.saveToolbar.startSaving();

  let egfrs = this.egfrs || [];

  const payload: EgfrForm = {
    id: Math.random().toString(16).slice(2),
    egfr_date: this.editState.egfr_date,
    egfr: this.editState.egfr,
    comments: this.editState.comments
  };

  if (!this.isNew ){  
    payload.id = this.editState.id;
    const foundIndex = egfrs.findIndex((meld: any) => meld.id == this.editState.id);
    
    if (foundIndex != -1) {
      egfrs[foundIndex] = payload;
    }
  } else {  
    egfrs.push(payload);
  }
  
  this.protoBucket.setItem(EDITSTATE_KEY, egfrs);

  // Call the 'showFakeSuccess' method to complete successful save animation
  if (this.saveToolbar) this.saveToolbar.showFakeSuccess();
  this.clearForm();
} 


private editSelected(row: any) {
    if (row) {  
      this.editState.id = row.row.id;
      this.editState.egfr_date = row.row.egfr_date;
      this.editState.egfr = row.row.egfr;
      this.editState.comments = row.row.comments;
      this.isNew = false;
    }
  }

  public clearForm(): any {
    this.isNew = false;
    this.editState.egfr_date = null;
    this.editState.egfr = null;
    this.editState.comments = "";
  }

  rowStyleClass(row: any) {
  // if row editable, change color
  return row.editable ? 'tr-highlight-green' : null;
}

  // Reference to table
  get egfrsTable(): any {
    return this.$refs.egfrsTable as any ;
  }

   // Saved successfully
   public onSaveSuccess() {
    this.egfrsTable.resetSelection();
  }

  // Deleted item
  public onDeleted() {
    this.egfrsTable.resetSelection();
  }


  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveEgfr as unknown as SaveProvider;

    saveToolbar.resetSaveToolbar();
  }
  
}



</script>

<style>
</style>
