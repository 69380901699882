<template>
  <!-- Form layout -->
  <validation-observer ref="validations">
    <form-layout
      :disabled="!enableForm"
      form-id="recipientEgfr-form">
      <template v-slot:title>
        <!-- Mode indicator / subsection form title -->
        <legend>
          <h5 v-if="selection?.isNew" class="legend-title">
            {{$t('egfr_new_record')}}
          </h5>
          <h5 v-else class="legend-title has-guiding-text">
            {{$t('egfr_selected_record')}}
          </h5>
        </legend>
      </template>

      <template v-slot:contents>
        <loading-form v-if="!editState.loaded" />
        <div v-else>
          <div class="row">
            <div class="standard-form-group">
              <date-input
                ruleKey="patient_egfr.date"
                inputId="egfr_date"
                :name="$t('egfr_date')"
                v-model="editState.date"   
              />
            </div>
            <div class="standard-form-group">
              <number-input
                ruleKey="patient_egfr.egfr"
                inputId="egfr_value"
                :name="$t('egfr_egfr')"
                :append="true"
                appendText="mL/min/1.73m²"
                step="0.1"
                v-model="editState.egfr"
              />
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group-large">
              <text-area-input 
                ruleKey="patient_egfr.comments"
                input-id="egfr_comments" 
                :name="$t('egfr_comments')"
                rows="4" 
                v-model="editState.comments"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:save>
        <save-toolbar
          :show="enableSaveToolbar"
          ref="saveToolbar"
          class="card-footer action-row temp-saving row"
          :label="$t('egfr_save_record')"
          :cancelButton="true"
          @save="handleSave()"
          @cancel="handleCancel()"
        />
      </template>

    </form-layout>
  </validation-observer>
</template>

<script lang="ts">
import { Getter } from 'vuex-facing-decorator';
import { Component, Prop, Watch, mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import LoadingFields from '@/components/shared/loading-skeletons/LoadingFields.vue';
import { UIRecipientEgfr } from '@/UIModels/recipients/recipientEgfr';
import { UIConfiguration } from '@/UIModels/configuration';
import { UIRecipient } from '@/UIModels/recipient';
import { useCurrentPageStore } from '@/stores/currentPage';
import FormLayout from '@/components/shared/FormLayout.vue';
import SaveToolbar from "@/components/shared/SaveToolbar.vue";
import { parseFormErrors } from '@/utils';
import { i18nMessages } from "@/i18n";
import { IdLookup } from '@/store/validations/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import NumberInput from "@/components/shared/NumberInput.vue";
import LoadingForm from '@/components/shared/loading-skeletons/LoadingForm.vue';
import { UIListFormSelection } from '@/UIModels/listFormSelection';
import { RulesQuery } from '@/types';
import { SaveResult, APIPermittedActions } from "@/types";

@Component({
  components: {
    LoadingFields,
    FormLayout,
    SaveToolbar,
    TextAreaInput,
    DateInput,
    NumberInput,
    LoadingForm
  },
  ...i18nMessages([
    require('@/components/recipients/_locales/common.json'),
    require('@/components/recipients/_locales/RecipientEgfrDialysis.json'),
  ]),
  emits: [
    'loaded',
    'success'
  ],
})
export default class EgfrForm extends mixins(DateUtilsMixin) {
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Selection instance
  @Prop({ required: true }) selection!: UIListFormSelection;
  @Prop({ default: false }) canSave!: boolean;

  // Editable view model for the form
  private editState = new UIRecipientEgfr();
  private permittedActions: string[] = [];

  private isLoadingForm = false;
  private isSuccessfullySaving = false;

  // Can we enable the form?
  get enableForm(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we show the save toolbar?
  get showSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Can we enable the save toolbar?
  get enableSaveToolbar(): boolean {
    return this.permittedActionsAllowCreateOrUpdate && this.canSave;
  }

  // Check permitted actions list
  get permittedActionsAllowCreateOrUpdate(): boolean {
    if (!this.editState) return false;
    // First we check special case, on #new endpoint permitted_actions is an empty array
    if (this.permittedActions.length === 0) return true;

    // We have a list of permitted actions, so now we can check for "update" keyword
    return this.permittedActions.includes(APIPermittedActions.Update);
  }

  // Which Configuration view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentConfiguration(): UIConfiguration {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.configuration as UIConfiguration;
  }

  // Which Recipient view model are we viewing on the current page?
  // NOTE: this is shared client state from the pinia store
  get currentRecipient(): UIRecipient {
    const currentPageStore = useCurrentPageStore();
    return currentPageStore.currentRecipient as UIRecipient;
  }

  // Process change to "selection" prop
  // NOTE: this will initialize Follow Up Form
  @Watch('selection', { immediate: true, deep: true })
  private handleSelectionChange(_newSelection: UIRecipientEgfr, oldSelection?: UIRecipientEgfr): void {
    if (!oldSelection) return;

    this.resetEditState();
    this.resetFormErrors();

    // Reset save toolbar (unless it is showing success indicator)
    if (!this.isSuccessfullySaving) this.resetSaveToolbar();
    this.isSuccessfullySaving = false;
  }

  // Initialize form edit state by requesting a copy of the "selection" view model
  private async resetEditState(): Promise<void> {
    this.isLoadingForm = true;

    const newEditState = new UIRecipientEgfr();

    const query = this.selection.isNew ? this.ruleQueryDefaults : this.ruleQueryEditState;
    await newEditState.load({ recipientId: this.currentRecipient.clientId as string, id: this.selection.id as string, query });

    this.editState = newEditState;
    this.isLoadingForm = false;
  }

  // What validation rule query parameters are needed based on lookup defaults?
  get ruleQueryDefaults(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    return params;
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process save button click event
  private async handleSave(): Promise<void> {
    this.resetFormErrors();
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const saveParams: any = { 
      selected: this.editState,
      recipient: this.currentRecipient
    };
        
    try {
      const success: SaveResult = await this.editState.save(saveParams);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Clear selection
  private async handleSuccess(success: SaveResult): Promise<void> {
    this.isSuccessfullySaving = true;
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
    this.$emit('success', success);
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup());

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  // Reset form when cancel is clicked
  public handleCancel(): void {
    this.resetEditState();
    this.resetFormErrors();
    this.resetSaveToolbar();
  }

  // Clears all save notifications shown by the form.
  public resetSaveToolbar(): void {
    if (this.saveToolbar) this.saveToolbar.reset();
  }

  // Reference to the form's save toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveToolbar;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
   private async mounted(): Promise<void> {
    this.resetEditState();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    const mapping: IdLookup = {
      'patient_egfr.date'       : 'egfr_date',
      'patient_egfr.egfr'       : 'egfr_value',
      'patient_egfr.comments'   : 'egfr_comments',
    };
    return mapping;
  }
}
</script>
